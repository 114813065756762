import $ from 'jquery'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './component.scss'
import './images/down_arrow.svg'

const DROPDOWN_ROOT_SELECTOR = '.js-dropdown-root-target'
const DROPDOWN_BUTTON_TRIGGER_SELECTOR = '.js-dropdown-button-trigger'
const DROPDOWN_ARROW_TARGET_SELECTOR = '.js-dropdown-arrow-target'
const DROPDOWN_MENU_TARGET_SELECTOR = '.js-dropdown-menu-target'
const MENU_SHOW_CLASS_NAME = 'dropdown-button__menu--show'
const ARROW_OPEN_CLASS_NAME = 'dropdown-button__arrow--open'
const BUTTON_OPEN_CLASS_NAME = 'dropdown-button__trigger--open'

$(() => {
  $(DROPDOWN_BUTTON_TRIGGER_SELECTOR).on('click', (event) => {
    const $trigger = $(event.currentTarget)
    const $dropdownRoot = $trigger.closest(DROPDOWN_ROOT_SELECTOR)
    const $menuTarget = $dropdownRoot.find(DROPDOWN_MENU_TARGET_SELECTOR)
    const $arrowTarget = $dropdownRoot.find(DROPDOWN_ARROW_TARGET_SELECTOR)

    // 別のメニューが開かれていたら閉じる
    $(DROPDOWN_BUTTON_TRIGGER_SELECTOR).not($trigger).removeClass(BUTTON_OPEN_CLASS_NAME)
    $(DROPDOWN_MENU_TARGET_SELECTOR).not($menuTarget).removeClass(MENU_SHOW_CLASS_NAME)
    $(DROPDOWN_ARROW_TARGET_SELECTOR).not($arrowTarget).removeClass(ARROW_OPEN_CLASS_NAME)

    $trigger.toggleClass(BUTTON_OPEN_CLASS_NAME)
    $menuTarget.toggleClass(MENU_SHOW_CLASS_NAME)
    $arrowTarget.toggleClass(ARROW_OPEN_CLASS_NAME)
  })

  // メニュー以外をクリックしたらメニューを閉じる
  $(document).on('click', (event) => {
    const $target = $(event.target)
    if (!$target.closest(DROPDOWN_ROOT_SELECTOR).length) {
      $(DROPDOWN_BUTTON_TRIGGER_SELECTOR).removeClass(BUTTON_OPEN_CLASS_NAME)
      $(DROPDOWN_MENU_TARGET_SELECTOR).removeClass(MENU_SHOW_CLASS_NAME)
      $(DROPDOWN_ARROW_TARGET_SELECTOR).removeClass(ARROW_OPEN_CLASS_NAME)
    }
  })
})
