import $ from 'jquery'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import './component.scss'
import './images/logo.png'
import './images/hamburger_menu.svg'
import './images/close.svg'
import './images/building.svg'
import './images/magnifying_glass.svg'
import './images/book.svg'
import './images/speech_bubble.svg'
import './images/arrow_right.svg'
import './images/nurikaeru.svg'

const openHeaderMenu = () => {
  // セレクタ定義
  const ROOT_TARGET_SELECTOR = '.js-openHeaderMenu-root-target'
  const OPEN_BUTTON_TRIGGER_SELECTOR = '.js-openHeaderMenu-openButton-trigger'
  const CLOSE_BUTTON_TRIGGER_SELECTOR = '.js-openHeaderMenu-closeButton-trigger'
  const MENU_TARGET_SELECTOR = '.js-openHeaderMenu-menu-target'

  // 表示状態のクラス定義
  const OPEN_CLASS_NAME = 'header-sp--open'

  // 処理
  const $rootElement = $(ROOT_TARGET_SELECTOR)

  $rootElement.each((index, root) => {
    const $openButtonTrigger = $(root).find(OPEN_BUTTON_TRIGGER_SELECTOR)
    const $closeButtonTrigger = $(root).find(CLOSE_BUTTON_TRIGGER_SELECTOR)
    const $menuTarget = $(root).find(MENU_TARGET_SELECTOR)

    $openButtonTrigger.on('click', () => {
      // スクロール位置を一番上にする
      window.scrollTo(0, 0)

      // 画面ロック
      $('body').css('overflow', 'hidden')

      // メニューの表示処理
      $(root).addClass(OPEN_CLASS_NAME)
      $menuTarget.show()
    })

    $closeButtonTrigger.on('click', () => {
      // スクロール位置を一番上にする
      window.scrollTo(0, 0)

      // 画面ロック解除
      $('body').css('overflow', '')

      // メニューの表示処理
      $(root).removeClass(OPEN_CLASS_NAME)
      $menuTarget.slideUp('fast')
    })
  })
}

$(() => {
  // SPヘッダーメニューを開く処理
  openHeaderMenu()
})
