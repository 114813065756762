import $ from 'jquery'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import './component.scss'

import './images/arrow.svg'

const SHOW_TARGET_SELECTOR = '.js-showScrollToTopButton-target'

const debounce = (callbackFunc, delay = 200) => {
  let timeoutId
  return (...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      callbackFunc(...args)
    }, delay)
  }
}

const showScrollToTopButton = () => {
  const $showTarget = $(SHOW_TARGET_SELECTOR)

  const showHandler = () => {
    if ($(window).scrollTop() >= $(window).height()) {
      $showTarget.fadeIn()
    } else {
      $showTarget.fadeOut()
    }
  }

  // 初期値判定
  showHandler()

  // イベント登録
  $(window).on('scroll', debounce(showHandler))
  $(window).on('resize', debounce(showHandler))
}

const scrollToTop = () => {
  const TRIGGER_SELECTOR = '.js-scrollToTop-trigger'

  $(TRIGGER_SELECTOR).on('click', () => {
    $(SHOW_TARGET_SELECTOR).fadeOut()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  })
}

$(() => {
  // ページトップへ戻るの表示制御
  showScrollToTopButton()

  // ページトップに戻る処理
  scrollToTop()
})
