import $ from 'jquery'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './component.scss'
import 'Components/nurikae/dropdown_menu_frame/component'

const DROPDOWN_AREA_MENU_ROOT_SELECTOR = '.js-dropdownAreaMenu-root'
const OPEN_PREFECTURE_TRIGEGR_SELECTOR = '.js-openPrefecture-trigger'
const OPEN_PREFECTURE_TARGET_SELECTOR = '.js-openPrefecture-target'
const REGION_ITEM_ACTIVE_CLASS_NAME = 'dropdown-area-menu__region-item--active'
const PREFECTURE_GROUP_ACTIVE_CLASS_NAME = 'dropdown-area-menu__prefecture-group--active'
const DROPDOWN_AREA_MENU_ACTIVE_CLASS_NAME = 'dropdown-area-menu--active'

$(() => {
  $(OPEN_PREFECTURE_TRIGEGR_SELECTOR).on('mouseenter', (event) => {
    const $trigger = $(event.currentTarget)
    const regionId = $trigger.data('region-id')
    const $target = $trigger.closest(DROPDOWN_AREA_MENU_ROOT_SELECTOR).find(`${OPEN_PREFECTURE_TARGET_SELECTOR}[data-region-id="${regionId}"]`)

    $(OPEN_PREFECTURE_TRIGEGR_SELECTOR).removeClass(REGION_ITEM_ACTIVE_CLASS_NAME)
    $(OPEN_PREFECTURE_TARGET_SELECTOR).removeClass(PREFECTURE_GROUP_ACTIVE_CLASS_NAME)
    $(DROPDOWN_AREA_MENU_ROOT_SELECTOR).removeClass(DROPDOWN_AREA_MENU_ACTIVE_CLASS_NAME)

    $trigger.addClass(REGION_ITEM_ACTIVE_CLASS_NAME)
    $target.addClass(PREFECTURE_GROUP_ACTIVE_CLASS_NAME)
    $(DROPDOWN_AREA_MENU_ROOT_SELECTOR).addClass(DROPDOWN_AREA_MENU_ACTIVE_CLASS_NAME)
  })

  $(OPEN_PREFECTURE_TRIGEGR_SELECTOR).add(OPEN_PREFECTURE_TARGET_SELECTOR).on('mouseleave', (event) => {
    const $trigger = $(event.currentTarget)
    const regionId = $trigger.data('region-id')
    const $target = $trigger.closest(DROPDOWN_AREA_MENU_ROOT_SELECTOR).find(`${OPEN_PREFECTURE_TARGET_SELECTOR}[data-region-id="${regionId}"]`)

    if (!$target.is(':hover')) {
      $target.removeClass(PREFECTURE_GROUP_ACTIVE_CLASS_NAME)
      $(OPEN_PREFECTURE_TRIGEGR_SELECTOR).removeClass(REGION_ITEM_ACTIVE_CLASS_NAME)
      $(DROPDOWN_AREA_MENU_ROOT_SELECTOR).removeClass(DROPDOWN_AREA_MENU_ACTIVE_CLASS_NAME)
    }
  })
})
