import './component.scss'

import './images/tag.png'
import './images/letter.png'

import '@stimulus/polyfills'
import { Application, Controller } from 'stimulus'

import $ from 'jquery'

const app = Application.start()

app.register('hover-search-for-area-button', class extends Controller {
  static classes = [ 'content' ]

  showAreaLinks() {
    $(`.${this.contentClass}`).addClass('global-navigation-area--show')
  }

  hideAreaLinks() {
    $(`.${this.contentClass}`).removeClass('global-navigation-area--show')
  }
})

app.register('sp-open-menu', class extends Controller {
  initialize() {
    this.showSliderNavigationContent = document.getElementById('js-site-slider-navigation')
  }

  openMenu() {
    this.showSliderNavigationContent.classList.add('site-slider-navigation--show')
    document.body.style.position = 'fixed'
  }

  closeMenu(e) {
    const closestClassName = e.target.closest('.site-slider-navigation__body')

    if (closestClassName === null) {
      this.showSliderNavigationContent.classList.remove('site-slider-navigation--show')
      document.body.style.position = ''
    }
  }
})

app.register('sp-toggle-icon', class extends Controller {
  initialize() {
    this.closeArrowClassName = 'site-slider-navigation__menu-item-link--close-arrow'
    this.openArrowClassName = 'site-slider-navigation__menu-item-link--open-arrow'
  }

  switchToggleArrow(e) {
    const classList = e.target.classList

    if (classList.contains(this.closeArrowClassName)) {
      classList.remove(this.closeArrowClassName)
      classList.add(this.openArrowClassName)
    } else if (classList.contains(this.openArrowClassName)) {
      classList.remove(this.openArrowClassName)
      classList.add(this.closeArrowClassName)
    }
  }
})

app.register('accordion', class extends Controller {
  static classes = [ 'content' ]

  switchAccordion() {
    const $accordionClass = $(document.getElementsByClassName(this.contentClass)[0])

    $accordionClass.slideToggle()
  }
})
